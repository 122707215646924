import React, { useState } from 'react';
import './styles/App.scss';
import Loader from './components/loader/Loader';

const imagePaths = [
  '/assets/images/image1.jpg',
  '/assets/images/image2.jpg',
  '/assets/images/image3.jpg',
  '/assets/images/image4.jpg',
  '/assets/images/image5.jpg',
  '/assets/images/image6.jpg',
];

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true); 

  const handleLoaderComplete = () => {
    console.log('Лоадер завершен');
    setIsLoading(false); 
  };

  return (
    <div className="app">
      <Loader 
        onLoaderComplete={handleLoaderComplete} 
        imagePaths={imagePaths} 
        isLoading={isLoading}
      />

      {!isLoading && (
        <div className="main-content">
          <h1>Добро пожаловать на сайт гадина</h1>
          <div className="images">
            {imagePaths.map((src, index) => (
              <img key={index} src={src} alt={`Изображение ${index + 1}`} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};


export default App;
