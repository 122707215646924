import React, { useState, useEffect, useCallback } from 'react';
import loaderIn from './loader-in.webp'; 
import loaderCycle from './loader-cycle.webp'; 
import './Loader.scss';

export interface LoaderProps {
  onLoaderComplete: () => void;
  imagePaths: string[]; 
  isLoading: boolean; 
}

const Loader: React.FC<LoaderProps> = ({ onLoaderComplete, imagePaths, isLoading }) => {
  const [animationStage, setAnimationStage] = useState<'in' | 'loading'>('in'); 
  const [isPreloaded, setIsPreloaded] = useState(false); 
  const [isContentLoaded, setIsContentLoaded] = useState(false); 
  const [isFirstRender, setIsFirstRender] = useState(true); 

  
  const preloadContentImages = useCallback((): Promise<void> => {
    return new Promise((resolve, reject) => {
      let loadedImages = 0;
      imagePaths.forEach((src) => {
        const img = new Image();
        img.src = src;

        img.onload = () => {
          loadedImages += 1;
          if (loadedImages === imagePaths.length) {
            resolve(); 
          }
        };

        img.onerror = () => {
          reject(); 
        };
      });
    });
  }, [imagePaths]);

  useEffect(() => {
    const img1 = new Image();
    img1.src = loaderIn;
    img1.onload = () => {
      console.log("Анимация 'loading-in' предзагружена!");
      const img2 = new Image();
      img2.src = loaderCycle;
      img2.onload = () => {
        console.log("Анимация 'loading-cycle' предзагружена!");
        setIsPreloaded(true); 
      };
    };
  }, []); 

  useEffect(() => {
    if (isPreloaded && animationStage === 'in') {
      console.log("Переход к этапу 'loading'");
      setTimeout(() => {
        setAnimationStage('loading'); 
      }, 2000); 
    }
  }, [isPreloaded, animationStage]); 

  useEffect(() => {
    if (animationStage === 'loading') {
      console.log("Цикличная анимация завершена, загружаем изображения...");
      setTimeout(async () => {
        await preloadContentImages(); 
        setIsContentLoaded(true); 
        onLoaderComplete(); 
      }, 1000); 
    }
  }, [animationStage, onLoaderComplete, preloadContentImages]); 

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false); 
    }
  }, [isFirstRender]);

  if (!isLoading || isContentLoaded) {
    return null; 
  }

  return (
    <div className="loader">
      <div className="loader-content">
        {animationStage === 'in' && !isFirstRender ? (
          <img src={loaderIn} alt="Loader Start Animation" />
        ) : (
          <img src={loaderCycle} alt="Loader Cycle Animation" />
        )}
      </div>
    </div>
  );
};

export default Loader;
